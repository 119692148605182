<template>
  <v-container align="center" id="members-idx-main-container">
    <v-row align="center" justify="end" class="mt-12" id="members-idx-main-row">
      <v-col cols="12" sm="12" md="8" v-if="membersState.isLoading" id="loading-col">
        <v-progress-circular indeterminate color="primary" id="loading-indicator"></v-progress-circular>
      </v-col>
      <v-col cols="12" sm="12" v-else id="members-list-col" class="pa-0">
        <members-list
          @loadNext="loadNext"
          @updateMember="updateMembersList"
          @removedUser="userRemoved"
          :members="membersData"
          :totalMembers="totalMembers"
          :isAdmin="true"
          :current_org="org"
          :roles="rolesList"
          id="members-list"
        ></members-list>
        <!-- :isAdmin="isOrgAdmin(org.handle)" -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MembersList from '@/components/Members/MembersList.vue';
import makeOrgService from '@/services/api/org';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      org: {},
      membersState: {
        isLoading: false,
        hasError: false,
        errorMessage: '',
      },
      membersData: [],
      totalMembers: 0,
      rolesList: ['member', 'admin', 'owner'],
      seatManager: {
        show: false,
      },
      navItems: [
        {
          name: 'Members',
          link: '#',
          value: 0,
        },
        {
          name: 'Pending invitations',
          link: '#',
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isOrgAdmin: 'user/isOrgAdmin',
      user: 'user/user',
      orgs: 'user/orgs',
      getOrg: 'user/getOrg',
      currentAccount: 'user/currentAccount',
    }),
  },
  components: { MembersList },

  methods: {
    ...mapActions({
      setCurrentAccount: 'user/setCurrentAccount',
      setOrgs: 'user/setOrgs',
      initSettings: 'user/initSettings',
    }),
    isActive(active) {
      return false;
    },
    /**
     * Load paginated data base on the users input
     * @param {Object} data
     */
    async loadNext(data) {
      await this.getMembers(data.perPage, data.currentPage - 1, this.$route.params.handle);
    },

    /**
     * handle user removeal
     * @param {Object} data
     *
     */
    userRemoved(data) {
      this.members = this.membersData.filter((member) => member.uid != data.memberId);
      if (this.user.uid == data.memberId) {
        let orgs = this.orgs.filter((org) => org.uid !== this.org.uid);
        this.setOrgs(orgs);
        if (this.org && this.currentAccount && this.org.uid === this.currentAccount.uid) {
          this.setCurrentAccount(null);
          this.initSettings();
        }
        this.$router.replace('/');
      }
    },
    /**
     * update member from members list
     * @param {Object} data
     */
    updateMembersList(newData) {
      const newRoleName = this.role(newData.newRole);
      let stateUpdated = false;
      //update role for the current list of members
      this.membersData = this.membersData.map((member) => {
        if (newData.memberId == member.uid) {
          return {
            ...member,
            roleUid: newData.newRole,
          };
        }
        return member;
      });
      //update role in orgs list
      if (this.user.uid == newData.memberId) {
        let orgs = this.orgs.map((item) => {
          if (item.uid == this.org.uid) {
            return {
              ...item,
              roleUid: newData.newRole,
              roleName: newRoleName,
            };
          }
          return item;
        });
        this.setOrgs(orgs);
        stateUpdated = true;
      }
      //update currentAccount
      if (this.currentAccount.uid == this.org.uid) {
        this.setCurrentAccount({ ...this.currentAccount, roleUid: newRoleName, roleName: newData.newRoleName });
        stateUpdated = true;
      }
      if (stateUpdated) {
        // this.$router.replace('/');
      }
    },
    role(id) {
      return this.rolesList.find((perm) => perm.value === id).text;
    },
    /**
     * Load paginated members data using @handle
     * @param {Number} per_page
     * @param {Number} next_page
     * @param {String} handle
     */
    async getMembers(per_page, next_page, handle) {
      const orgService = makeOrgService(this.$api);
      this.membersState.isLoading = true;
      try {
        const response = await orgService.getUsers(handle, { per_page: per_page, next_page: next_page });
        this.membersData.push(...response.data.users);
        if (next_page == 0) {
          let membersCount = response.data.totalMembers;
          this.totalMembers = membersCount;
          this.navItems[0].value = membersCount;
        }
        this.membersState.isLoading = false;
      } catch (err) {
        this.membersState.isLoading = false;
        this.membersState.hasError = false;
        this.membersState.errorMessage = 'Having problem loading members ' + err.message;
      }
    },
  },
  /**
   * Load members data using @orgName before the page is rendered
   */
  async mounted() {
    this.org = this.getOrg(this.$route.params.handle);
    await this.getMembers(30, 0, this.org.uid);
  },
};
</script>
